import styled from 'styled-components'
import { colors, typography, mq } from '../../../styles/utilities/variables'
import StyledHeading from '../../Heading/style'

const StyledBikeSelectorStart = styled.div`
  background: ${colors.secondaryYellow};
  height: 80vh;
  display: flex;
  flex-direction: column;
  justify-content: center;

  ${StyledHeading.h1} {
    font-family: ${typography.primaryFont};
    font-size: ${typography.sizes.xxLarge};

    @media ${mq.mobile} {
      font-size: ${typography.sizes.xLarge};
    }
  }
`

export default StyledBikeSelectorStart
