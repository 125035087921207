import React from 'react'
import PropTypes from 'prop-types'
import StyledBikeSelectorStart from './style'
import { PreHeader } from '../../PreHeader'
import { Heading } from '../../Heading'
import { Text } from '../../Text'
import { Button } from '../../Button'
import { Container } from '../../Container'
import { TEXT_SIZES, SPACING } from '../../../helpers/constants'
import { Divider } from '../../Divider'
import { getUrl } from '../../../helpers/url'

const BikeSelectorStart = props => {
  const { data } = props
  const {
    bikeSelectorStartPagePreheader: preheader,
    bikeSelectorStartPageTitle: title,
    bikeSelectorStartPageText: text,
    bikeSelectorQuestionsPageLink: linkToQuestions,
  } = data

  return (
    <StyledBikeSelectorStart>
      <Container>
        <PreHeader>{preheader}</PreHeader>
        <Divider size={SPACING.X_SMALL} />
        <Heading size={1} type={'h1'} bold>
          <span dangerouslySetInnerHTML={{ __html: title }} />
        </Heading>
        <Divider size={SPACING.X_SMALL} />
        <Text size={TEXT_SIZES.MEDIUM}>{text}</Text>
        <Divider size={SPACING.SMALL} />
        <Button icon={'arrow_forward'} href={getUrl(linkToQuestions.url)}>
          {linkToQuestions.title}
        </Button>
      </Container>
    </StyledBikeSelectorStart>
  )
}

BikeSelectorStart.propTypes = {
  data: PropTypes.object,
}

export { BikeSelectorStart }
