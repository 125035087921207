import React from 'react'
import PropTypes from 'prop-types'
import StyledPreHeader from './style'

/**
 * PreHeader
 * @param {*} props
 */
const PreHeader = props => {
  const { children } = props
  return <StyledPreHeader>{children}</StyledPreHeader>
}
PreHeader.propTypes = {
  children: PropTypes.any,
}

export { PreHeader }
