/**
 * Parse Yoast SEO
 * @param {*} param
 */
export const parseSeo = ({
  title,
  metaDesc,
  metaKeywords,
  canonical,
  opengraphType,
  opengraphTitle,
  opengraphDescription,
  opengraphImage,
}) => {
  return {
    title: title,
    description: metaDesc,
    keywords: metaKeywords,
    canonical: canonical,
    openGraphType: opengraphType,
    openGraphTitle: opengraphTitle,
    openGraphDescription: opengraphDescription,
    openGraphImage: opengraphImage ? opengraphImage.sourceUrl : null,
  }
}
