import styled from 'styled-components'
import { colors, typography } from '../../styles/utilities/variables'

const StyledPreHeader = styled.p`
  /* text-transform: uppercase; */
  font-size: ${typography.sizes.small};
  margin: 0;
  /* font-weight: bold; */
  letter-spacing: 0.05rem;
  color: ${colors.primaryDarkBlue};
`

export default StyledPreHeader
